import { Injectable, signal } from '@angular/core';
import { Theme } from '../../types/theme-types';

@Injectable({
  providedIn: 'root',
})
export class ThemeService {
  //FIXME (research) may have to be adjusted
  themeSig = signal<Theme>('dark-theme');

  setTheme(theme: Theme) {
    this.themeSig.set(theme);
  }
}
